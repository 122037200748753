<template>
    <v-row no-gutters justify="center">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="interactionError">
                <p class="text-h5 font-weight-light red--text">We cannot process this request.</p>
            </template>
            <template v-if="errorUnauthorizedInteraction">
                <p class="text-h5 font-weight-light red--text">Unauthorized</p>
                <p class="text-h5 font-weight-light">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                <p class="text-body-1 font-weight-light text-center"><router-link to="/">Start over</router-link></p>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #e1bee7; /* green lighten-4 */
    border-top: 4px solid #9c27b0; /* green */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { client } from '@/client';

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        interactionId: null,
        // loading: true,
        interactionError: false,
        errorUnauthorizedInteraction: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        async init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.$route.query.token) {
                await this.resumeInteraction(this.$route.query.token);
            }
            if (this.interactionId) {
                await this.loadInteraction();
            }
            this.isViewReady = true;
        },
        async resumeInteraction(token) {
            try {
                this.$store.commit('loading', { resumeInteraction: true });
                const response = await this.$client.main().interaction.resume(token);
                console.log(`resumeInteraction response: ${JSON.stringify(response)}`);
                const { interaction_id: interactionId, next_route: nextRoute, error } = response;
                if (error) {
                    this.interactionError = true;
                } else if (nextRoute) {
                    console.log(`resumeInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;
                }
            } catch (err) {
                console.error('failed to activate token', err);
            } finally {
                this.$store.commit('loading', { resumeInteraction: false });
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`loadInteraction response: ${JSON.stringify(response)}`);
                const { type, next, state } = response;
                console.log(`interaction.vue: loadInteraction: interaction type: ${type} next: ${next} state ${JSON.stringify(state)}`);
                // switch (type) {
                // case 'create_account':
                //     this.createAccount({ type, next, state }, prevInteraction);
                //     break;
                // default:
                //     console.error('interaction.vue: unknown interaction type: %s', type);
                //     this.interactionError = true;
                //     break;
                // }
            } catch (err) {
                console.error('failed to activate token', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        // createAccount({ state }) {
        //     const { isVerified, isExisting } = state;
        //     if (isExisting) {
        //         this.$router.replace({ path: '/create-account/conflict', query: { i: this.interactionId } });
        //         return;
        //     }
        //     if (isVerified) {
        //         this.$router.replace({ path: '/create-account/login', query: { i: this.interactionId } });
        //         return;
        //     }
        //     this.interactionError = true;
        // },
    },
    // created() {
    //     if (this.$route.query.error) {
    //         console.log(`interaction.vue: created, error: ${this.$route.query.error}`);
    //         this.loading = false;
    //         switch (this.$route.query.error) {
    //         case 'unauthorized':
    //             this.errorUnauthorizedInteraction = true;
    //             break;
    //         default:
    //             this.interactionError = true;
    //         }
    //     } else {
    //         this.interactionId = this.$route.query.i;
    //         console.log(`interaction.vue: created, interactionId: ${this.interactionId}`);
    //     }
    // },
    mounted() {
        console.log('interaction.vue: mounted');
        this.init();
    },
};
</script>
